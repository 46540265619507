// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/free.min.css";
// // Import Flag Icons Set
@import "~flag-icon-css/css/flag-icon.min.css";
// // Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// // Import Simple Line Icons Set
@import "~simple-line-icons/css/simple-line-icons.css";
// // Import Main styles for this application
@import "~@coreui/coreui/scss/coreui.scss";

// // Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

// ie fix
html body .app.flex-row.align-items-center {
  height: 100vh;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}
.sidebar .nav-link i {
  color: #99ca93;
}
.sidebar .nav-link.active .nav-icon {
  color: #99ca93;
}
.sidebar .nav-link:hover {
  color: #fff;
  background: #1e2225;
}
.sidebar .nav-link.active {
  color: #fff;
  background: #1e2225;
}
.modal {
  background: rgba(0, 0, 0, 0.3);
}
#loading .modal-content {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: white;
}
::-webkit-scrollbar {
  // display: none;
  width: 0px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  height: 10px;
}
.divider {
  border-bottom: 1px dashed #8d9093;
  padding: 10px;
}
.btn-ghost-back {
  color: #1e2225;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
  font-weight: bold;
}
.dividerTitle {
  box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.2) !important;
  margin-bottom: 10px;
  padding: 2px;
}
.evaluateItemContainer {
  border-radius: 10px;
  border: 1px solid #0b5563;
  box-shadow: 2px 4px rgba(0, 0, 0, 0.2);
  min-height: 200px;
  margin: 20px 5px;
}
.evaluateItemContainer h4 {
  position: absolute;
  margin-top: -27px;
  padding: 10px;
  background-color: #e4e5e6;
  color: #0b5563;
}
.evaluateItemBody {
  padding: 10px;
}
.evaluateItemBody .row {
  border-bottom: 1px dashed #8d9093;
  margin: 0 5px;
  padding: 10px;
}
.writingEvaluate {
  padding: 10px;
  margin: 0 5px;
}
.evaluateItemTitle {
  text-align: center;
  padding: 10px;
}
.audioPlayer {
  padding: 30px;
}
.totalPoint {
  position: fixed;
  border: 1px solid #0b5563;
  box-shadow: 2px 4px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  border-radius: 10px;
  width: 20vw;
  height: 10vh;
  bottom: 52px;
  right: 10px;
  z-index: 100;
  text-align: center;
}
